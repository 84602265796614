// @file Composable for Google Drive
/// <reference types="@types/google.picker" />

export const useGoogleDrive = (): {
  requestGoogleDriveAuthorization: typeof requestGoogleDriveAuthorization
} => {
  const requestGoogleDriveAuthorization = async ({
    tenantId,
    onCloseBeforeAuth = () => {},
  }: {
    tenantId: number
    onCloseBeforeAuth?: () => void
  }): Promise<{ email: string; token: string }> => {
    return await new Promise((resolve, reject) => {
      const cleanup = (): void => {
        window.removeEventListener('google_drive_auth', googleOauthTokenResponseHandler)
        clearInterval(interval)
      }

      const googleOauthTokenResponseHandler = (result) => {
        cleanup()
        return resolve({ email: result.detail.email, token: result.detail.accessToken })
      }

      window.addEventListener('google_drive_auth', googleOauthTokenResponseHandler)

      const oauthPath = `/auth/google_oauth2?tenant_id=${tenantId}&scope_alias=drive&afterwards=dispatch_parent_drive`
      const oauthWindow = window.open(oauthPath, 'google_oauth', 'height=600,width=450')

      if (oauthWindow != null) {
        oauthWindow.focus()
      } else {
        return reject(new Error('Failed to open OAuth window'))
      }

      // JavaScript does not provide a direct event listener for detecting when a window created via window.open is closed
      // Thus, we poll to check if it has been closed. This is required to perform cleanup if the user closed without actually authenticating
      const interval = setInterval(() => {
        if (oauthWindow.closed) {
          cleanup()
          onCloseBeforeAuth()
        }
      }, 500)
    })
  }

  return {
    requestGoogleDriveAuthorization,
  }
}
